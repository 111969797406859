<template>
  <SubHeader>
    Kettle
    <template #right> {{ popBrew.recipeCopy.kettle.totalTime }} min </template>
  </SubHeader>
  <CardBox>
    <BTable :header="['catogory', 'name', 'aa', 'boil', 'quantity', 'uid', 'done']">
      <tr v-for="(item, index) in popBrew.recipeCopy.kettle.items" :key="index">
        <BrewKettleTableItem :item="{ ...popIngredient(item.id), ...item }" :index="index" />
      </tr>
    </BTable>
  </CardBox>
</template>

<script>
import { mapGetters } from "vuex"

import comp from "../base"
import BrewKettleTableItem from "./BrewKettleTableItem.vue"

export default {
  name: "BrewSheetKattle",
  props: ["popBrew"],
  components: {
    ...comp,
    BrewKettleTableItem,
  },
  computed: {
    ...mapGetters(["popIngredient"]),
  },
}
</script>
