<template>
  <CardBox>
    <Row>
      <BCol>
        <Lable>
          Water temperature *
        </Lable>
      </BCol>
      <BCol>
        <Lable>
          Volume, proposed:
          {{ estimatedVolume }}
          L
        </Lable>
      </BCol>
      <BCol> </BCol>
    </Row>
    <Row>
      <BCol>
        <FormInputEnableable
          ref="formTemperature"
          type="number"
          :validateion="validNum"
          v-model.number="temperature"
          @lock="setTemperature()"
          span="°C"
        />
      </BCol>
      <BCol>
        <FormInputEnableable
          ref="formVolume"
          type="number"
          :validateion="validNum"
          v-model.number="volume"
          @lock="setVolume()"
          span="L"
        />
      </BCol>
      <BCol>
        <ButtonCheck :modelValue="popBrew[state].done" @click="setDone()" />
      </BCol>
    </Row>
  </CardBox>
</template>

<script>
import cloneDeep from "clone-deep"

import comp from "../base"
import { checkValidity, formError, validNum } from "../../services/validation"
import { apiSetBrew } from "../../services/api"
import { waterDensityKell } from "../../helper/calculate"
import { useStore } from "@/store/store"

export default {
  name: "BrewSheetWater",
  props: ["popBrew", "state"],
  components: {
    ...comp,
  },
  setup() {
    return {
      validNum,
    }
  },
  data() {
    return {
      done: null,
      volume: null,
      temperature: null,
    }
  },
  created() {
    this.setData()
  },
  watch: {
    popBrew() {
      this.setData()
    },
  },
  computed: {
    estimatedVolume() {
      let volume =
        this.state === "preBoilVolume"
          ? this.popBrew.recipeCopy.evaporated + this.popBrew.recipeCopy.batchSize
          : this.popBrew.recipeCopy.batchSize

      volume = this.temperature ? volume / waterDensityKell(this.temperature) : volume

      let volumeRound = Math.round(volume * 10) / 10

      return volumeRound
    },
  },
  methods: {
    setData() {
      this.done = this.popBrew[this.state].done
      this.volume = this.popBrew[this.state].volume
      this.temperature = this.popBrew[this.state].temperature
    },

    setTemperature() {
      let local = cloneDeep(useStore().state.collections.brews[this.popBrew.id])
      local[this.state].temperature = this.temperature

      if (checkValidity(this.$refs.formTemperature)) apiSetBrew(local)
      else formError()
    },

    setVolume() {
      let local = cloneDeep(useStore().state.collections.brews[this.popBrew.id])
      local[this.state].volume = this.volume

      if (checkValidity(this.$refs.formVolume)) apiSetBrew(local)
      else formError()
    },

    setDone() {
      let local = cloneDeep(useStore().state.collections.brews[this.popBrew.id])
      local[this.state].done = local[this.state].done ? false : true
      apiSetBrew(local)
    },
  },
}
</script>
