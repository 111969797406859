<template>
  <SubHeader>
    Mashing
    <template #right>
      {{ popBrew.recipeCopy.mashing.totalTime }}
    </template>
  </SubHeader>
  <CardBox>
    <BTable :header="['catogory', 'name', 'ebs', 'quantity', 'uid', 'done']">
      <tr v-for="(item, index) in popBrew.recipeCopy.mashing.items" :key="index">
        <BrewMashingTableItem :item="{ ...popIngredient(item.id), ...item }" :index="index" />
      </tr>
    </BTable>

    <ProfileGraph
      v-if="popBrew.mashingProfile"
      :chartId="'mashing'"
      :items="popBrew.mashingProfile.items"
      :totalTime="popBrew.mashingProfile.totalTime"
    />
  </CardBox>
</template>

<script>
import { mapGetters } from "vuex"

import comp from "../base"
import BrewMashingTableItem from "./BrewMashingTableItem.vue"
import ProfileGraph from "../recipe/ProfileGraph.vue"

export default {
  props: ["popBrew"],
  name: "BrewSheetMashing",
  components: {
    ...comp,
    BrewMashingTableItem,
    ProfileGraph,
  },
  computed: {
    ...mapGetters(["popIngredient"]),
  },
}
</script>
