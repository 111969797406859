<template>
  <Container>
    <Header>
      BrewSheet | {{ popBrew.formatCount }} <strong> {{ popBrew.recipeName }} </strong>
    </Header>
    <PageContent>
      <SubHeader>Pre Boil Volume</SubHeader>
      <BrewSheetWater :popBrew="popBrew" state="preBoilVolume" />

      <BrewSheetMashing :popBrew="popBrew" />

      <BrewSheetKettle :popBrew="popBrew" />

      <SubHeader>Post Boil Volume</SubHeader>
      <BrewSheetWater :popBrew="popBrew" state="postBoilVolume" />

      <BrewSheetFermentation :popBrew="popBrew" />
      <template #right>
        <BrewRecyme :popBrew="popBrew" />
      </template>
    </PageContent>
  </Container>
</template>

<script>
import comp from "../base"
import BrewSheetWater from "./BrewSheetWater.vue"
import BrewSheetMashing from "./BrewSheetMashing.vue"
import BrewSheetKettle from "./BrewSheetKettle.vue"
import BrewSheetFermentation from "./BrewSheetFermentation.vue"
import BrewRecyme from "../recymes/BrewRecyme.vue"
import { useStore } from "@/store/store"

export default {
  name: "BrewSheet",
  components: {
    ...comp,
    BrewSheetWater,
    BrewSheetKettle,
    BrewSheetMashing,
    BrewSheetFermentation,
    BrewRecyme,
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  computed: {
    popBrew() {
      return this.id ? useStore().getters.popBrew(this.id) : null
    },
  },
}
</script>
