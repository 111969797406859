<template>
  <td v-for="(callback, index) in dataCallbacl" :key="index">{{ callback(item) }}</td>
  <td>
    <FormInputEnableable ref="formUid" v-model="uid" @lock="setUid()" />
  </td>
  <td>
    <ButtonCheck :modelValue="item.done" @click="setDone()" />
  </td>
</template>

<script>
import comp from "../base"
import cloneDeep from "clone-deep"
import { apiSetBrew } from "../../services/api"
import { checkValidity, formError, validNum } from "../../services/validation"
import { useStore } from "@/store/store"

export default {
  name: "BrewFermentationTableItem",
  props: ["item", "index"],
  components: {
    ...comp,
  },
  data() {
    return {
      dataCallbacl: [
        item => item.categoryName,
        item => item.name,
        item => item.quantity + " " + item.unitText,
      ],
      brewId: this.$route.params.id,
      uid: null,
      done: null,
    }
  },
  setup() {
    return {
      validNum,
    }
  },
  created() {
    this.setData()
  },
  watch: {
    item() {
      this.setData()
    },
  },
  computed: {},
  methods: {
    setData() {
      if (this.item) {
        this.uid = this.item.uid
        this.done = this.item.done
      }
    },
    setUid() {
      let local = cloneDeep(useStore().state.collections.brews[this.brewId])
      local.recipeCopy.fermentation.items[this.index].uid = this.uid

      if (checkValidity(this.$refs.formUid)) apiSetBrew(local)
      else formError()
    },
    setDone() {
      let local = cloneDeep(useStore().state.collections.brews[this.brewId])
      let item = local.recipeCopy.fermentation.items[this.index]
      item.done = item.done ? false : true
      apiSetBrew(local)
    },
  },
}
</script>
